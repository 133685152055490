import { transformArrayObjectKeysToCamelCase } from '../utils/utils';
import api from './index';

async function getMyDashboardRequest(
  resource: string,
  search: string,
  expired: boolean,
  mine = false
): Promise<any> {
  const currentDate = new Date();

  const filters: {
    resource: string;
    start_day: string;
    mine: boolean;
    search?: string;
    expired?: boolean;
  } = {
    resource,
    start_day: currentDate.toLocaleDateString('pt-PT', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    }),
    mine,
  };

  if (search !== '') {
    filters.search = search;
  }

  filters.expired = false;
  if (expired) {
    filters.expired = true;
  }

  const response = await api.get('/my-dashboard', {
    params: filters,
  });

  // eslint-disable-next-line default-case
  switch (resource) {
    case 'interviews':
      return {
        interviewsList: transformArrayObjectKeysToCamelCase(
          response.data.interviews
        ),
      };
    case 'matters':
      return {
        mattersList: transformArrayObjectKeysToCamelCase(
          response.data.matters_list
        ),
      };
  }
  return response.data;
}

async function getMyDashboardGeneralRequest(): Promise<any> {
  const response = await api.get('/my-dashboard-general');
  return {
    activeMatters: response.data.active_matters,
    avgMatterDuration: response.data.avg_matter_duration,
    assignedTasks: response.data.assigned_tasks,
    scheduledInterviews: response.data.scheduled_interviews,
  };
}

async function getMyDashboardCalendarRequest(
  startDate: string,
  targetPage: string, // my-dashboard , clients , matter-detail
  targetId: string | null = null, // related id, matter or client
  wantedResources: string[] = ['tasks', 'matters', 'interviews']
): Promise<any> {
  const resourcesParam =
    wantedResources.length > 0 ? wantedResources.join(',') : '';
  const response = await api.get('/my-dashboard-calendar', {
    params: {
      resources: resourcesParam,
      start_day: startDate,
      page: targetPage,
      id: targetId,
    },
  });

  return response.data;
}

async function getEmailsData(resource: string, interview = null) {
  const response = await api.get('/emails', {
    params: {
      resource,
      interview_id: interview,
    },
  });
  return response.data;
}

async function postEmail(resource: string, data: any, interview = null) {
  const response = await api.post('/emails', {
    resource,
    interview_id: interview,
    message: data.message,
  });

  return response.data;
}

async function getMatterNotesList(
  searchText: string | null,
  matter: string | number,
  tags: any
) {
  const response = await api.get('/notes', {
    params: {
      search: searchText,
      matter,
      tags: JSON.stringify(tags),
    },
  });
  return response.data;
}

async function createNote(
  matter: string | number,
  subject: string,
  content: any,
  tags: any,
  privileged: boolean
) {
  const response = await api.post('/notes', {
    matter,
    subject,
    tags,
    content_json: content,
    privileged,
  });

  return response.data;
}

async function updateNote(
  noteId: string | number,
  subject: string,
  content: any,
  tags: any,
  privileged: boolean
) {
  const response = await api.patch(`/note/${noteId}`, {
    subject,
    tags,
    content_json: content,
    privileged,
  });

  return response.data;
}

async function batchDeleteNotes(notesIds: number[]) {
  const payload: { ids_to_delete?: number[] } = {
    ids_to_delete: [...notesIds],
  };

  await api.delete('/notes', { data: payload });
}

async function getTaggableNoteResources(matter: string | number, type: any) {
  const response = await api.get('/tags', {
    params: {
      matter,
      tag_type: type,
    },
  });
  return response.data;
}

async function updateReport(
  reportId: string | number,
  name: string,
  content: any
) {
  const response = await api.patch(`/matter-report/${reportId}`, {
    report_name: name,
    content_json: content,
  });

  return response.data;
}

async function exportReport(
  reportId: string | number,
  extension: string,
  content: any
) {
  const response = await api.post(
    `/matter-report/${reportId}`,
    {
      extension,
      content,
    },
    {
      responseType: 'blob',
    }
  );

  return response;
}

export {
  getMyDashboardRequest,
  getMyDashboardGeneralRequest,
  getMyDashboardCalendarRequest,
  getEmailsData,
  postEmail,
  getMatterNotesList,
  createNote,
  updateNote,
  batchDeleteNotes,
  getTaggableNoteResources,
  updateReport,
  exportReport,
};

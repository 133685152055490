import {
  Box,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getTaggableNoteResources } from '../../api/general';
import { listNoteTagStyles } from './styles';

const TAG_PROPERTIES: Record<
  string,
  { color: string; icon: string; name: string }
> = {
  investigation: { color: '#4682B4', icon: 'folder', name: 'Matter' },
  investigationdocument: {
    color: '#32CD32',
    icon: 'article',
    name: 'Document',
  },
  investigationtask: { color: '#1E90FF', icon: 'task', name: 'Task' },
  interview: { color: '#FFD700', icon: 'groups', name: 'Interview' },
  user: { color: '#FF69B4', icon: 'person', name: 'User' },
};

const DEFAULT_PROPERTIES = { color: '#A962D5', icon: 'help' };

interface NoteTagsEditorProps {
  matterId: any;
  tags: any[];
  handleRemoveTag: any;
  handleAddTag: any;
}

const NoteTagsEditor = ({
  matterId,
  tags,
  handleRemoveTag,
  handleAddTag,
}: NoteTagsEditorProps) => {
  const heightValue = '30px';
  const wrapperHeight = '36px';
  const [hoveredTag, setHoveredTag] = useState<string | null>(null);
  const [dropdownOptions, setDropdownOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTagType, setSelectedTagType] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (selectedTagType && dropdownOptions.length > 0) {
      setOpen(true);
    }
  }, [dropdownOptions, selectedTagType]);

  const getTagResources = async (tagType: string) => {
    try {
      setLoading(true);
      const response = await getTaggableNoteResources(matterId, tagType);
      setDropdownOptions(response);
      setLoading(false);
      setOpen(true); // Ensure the dropdown stays open after fetching resources
    } catch (error: unknown) {
      console.error(error);
      setLoading(false);
    }
  };

  if (!tags) return null;

  const renderTags = (tagsToRender: any[]) =>
    tagsToRender.map((tag) => {
      const { color: backgroundColor, icon } =
        TAG_PROPERTIES[tag.tag_type.toLowerCase()] || DEFAULT_PROPERTIES;

      const handleMouseEnter = () => {
        setHoveredTag(tag);
      };

      const handleMouseLeave = () => {
        setHoveredTag(null);
      };

      const removeTag = () => {
        handleRemoveTag(tag);
      };

      return (
        <Box
          key={uuidv4()}
          sx={{
            ...listNoteTagStyles(
              backgroundColor,
              '#FFFFFF',
              heightValue,
              wrapperHeight
            ),
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Box
            sx={{
              border: hoveredTag === tag ? '1px solid #FF7F7F' : 'none',
            }}
          >
            <Typography>
              <span className="material-icons-round">{icon}</span>
              <span>{tag.tag_name}</span>
              {hoveredTag === tag && (
                /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
                <span
                  className="material-icons-round"
                  style={{ cursor: 'pointer', color: 'red' }}
                  role="button"
                  tabIndex={0}
                  aria-label={`Remove tag ${tag.tag_name}`}
                  onClick={removeTag}
                >
                  close
                </span>
              )}
            </Typography>
          </Box>
        </Box>
      );
    });

  const handleDropdownChange = async (event: any) => {
    const selectedOption = event.target.value;

    if (!selectedTagType) {
      setSelectedTagType(selectedOption);
      await getTagResources(selectedOption);
    } else {
      const selectedResource = dropdownOptions.find(
        (option) => option.tag_name === selectedOption
      );
      if (selectedResource) {
        handleAddTag(selectedResource);
        setOpen(false);
        setDropdownOptions([]);
        setSelectedTagType(null);
      }
    }
  };

  const handleDropdownClose = () => {
    if (selectedTagType && !loading) {
      setSelectedTagType(null);
      setDropdownOptions([]);
      setOpen(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '8px',
        width: '100%',
      }}
    >
      {renderTags(tags)}
      <Select
        value=""
        onChange={handleDropdownChange}
        displayEmpty
        open={open}
        onClose={handleDropdownClose}
        sx={{
          height: heightValue,
          backgroundColor: 'lightgrey',
          borderRadius: '10px',
          padding: '6px 10px',
          color: 'white',
        }}
        onClick={() => setOpen(!open)}
      >
        <MenuItem value="" disabled>
          Add Tag
        </MenuItem>
        {(() => {
          if (loading) {
            return (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            );
          }

          if (!selectedTagType) {
            return Object.keys(TAG_PROPERTIES).map((tagType) => (
              <MenuItem key={tagType} value={tagType}>
                {TAG_PROPERTIES[tagType].name}
              </MenuItem>
            ));
          }

          return dropdownOptions.map((option) => (
            <MenuItem key={option.tag_resource_id} value={option.tag_name}>
              {option.tag_name}
            </MenuItem>
          ));
        })()}
      </Select>
    </Box>
  );
};

export default NoteTagsEditor;
